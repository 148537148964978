$(function(){
  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('ul').removeClass('open');
      $('.overlay').removeClass('open');
    } else {
      $(this).addClass('active');
      $(this).next('ul').addClass('open');
      $('.overlay').addClass('open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('.nav-sp ul').removeClass('open');
    }
  });
  if ($('body').hasClass('slick')) {
    $('.slider').slick({
      arrows: false,
      autoplay:true,
      autoplaySpeed:5000,
      dots:true,
      pauseOnHover: false,
      speed: 1005
  	});
  }
  if ($('body').hasClass('products-detail')) {
    var slider = ".slider"; // スライダー
    var thumbnailItem = ".thumbnail-list .thumbnail-item"; // サムネイル画像アイテム
    // サムネイル画像アイテムに data-index でindex番号を付与
    $(thumbnailItem).each(function(){
      var index = $(thumbnailItem).index(this);
      $(this).attr("data-index",index);
    });
    // スライダー初期化後、カレントのサムネイル画像にクラス「thumbnail-current」を付ける
    // 「slickスライダー作成」の前にこの記述は書いてください。
    $(slider).on('init',function(slick){
      var index = $(".slide-item.slick-slide.slick-current").attr("data-slick-index");
      $(thumbnailItem+'[data-index="'+index+'"]').addClass("thumbnail-current");
    });
    //slickスライダー初期化
    $(slider).slick({
      autoplay: false,
      arrows: false,
      autoplaySpeed:5000,
      fade: true,
      infinite: false //これはつけましょう。
    });
    //サムネイル画像アイテムをクリックしたときにスライダー切り替え
    $(thumbnailItem).on('click',function(){
      var index = $(this).attr("data-index");
      $(slider).slick("slickGoTo",index,false);
    });
    //サムネイル画像のカレントを切り替え
    $(slider).on('beforeChange',function(event,slick, currentSlide,nextSlide){
      $(thumbnailItem).each(function(){
      $(this).removeClass("thumbnail-current");
    });
    $(thumbnailItem+'[data-index="'+nextSlide+'"]').addClass("thumbnail-current");
    });
    // .allergie tr
    $('.allergie table tr').each(function(){
      var count = $('th',this).text().length;
      // console.log(count);
      $(this).addClass('flex-'+count);
    });
  }
  if ($('body').hasClass('home')) {
    $('.slider').slick({
      // arrows: false,
      autoplay:true,
      autoplaySpeed:5000,
      dots:true,
      pauseOnHover: false,
      speed: 1005,
      slidesToShow:1,
      centerMode: true,
      centerPadding:'30%',
      slidesToScroll:1,
      responsive: [{
        breakpoint: 1200,
        settings: {
          centerPadding:'30%'
        }
      },{
        breakpoint: 1024,
        settings: {
          centerPadding:'20%'
        }
      },{
        breakpoint: 684,
        settings: {
          centerPadding:'10%'
        }
      }]
  	});
  }
  if ($('body').hasClass('products-top')) {
    // トップ
    $('.tab-content > div').each(function(){
      if ($(this).hasClass('gif')) {
        // console.log($(this).attr('class'));
        var order = $(this).attr('class').split('giforder-')[1];
        // alert(order);
        $(this).insertBefore('.tab-content > div:nth-child('+order+')');
      }
    });

    if(location.hash){
      var urlHash = location.hash.split('#');
      $('.tab-content > div').css("display","none");
      $('.tab-content .s-' + urlHash[1]).css("display","block");
      $('.tab span').removeClass("select");
      $('.' + urlHash[1]).addClass("select");
    }else{
      $('.tab-content > div').css("display","none");
      $('.tab-content .s-cat0').css("display","block");
      $('.tab span#tab-all').addClass("select");
    }
    // トップカテゴリ表示
    $('.tab span').click(function(){
      var index = $(this).attr("id").split('tab-')[1];
      $('.tab-content > div').css("display","none");
      $('.tab-content .s-'+ index).css("display","block");
      $('.tab span').removeClass("current");
      $(this).addClass("current");
      location.hash = index;
    });
    // トップすべて表示
    $('.tab span#tab-all').click(function(){
      $('.tab-content > div').css("display","none").css("display","block");
      $('.tab span').removeClass("select");
      $(this).addClass("select");
      location.hash = "";
    });
  }
  if ($('body').hasClass('event-calendar')) {
    $(".widget-calendar:first .cal-prev").on('click',function(){
    });
  }
  if ($('body').hasClass('event-list')) {
    $("#event-cal").load("cal.html .onmonth", function(){
      $(".widget-calendar .cal-nav >div >span").on('click',function(){
        var prevmonth = $(this).attr('id').split('move')[1];
        var thismonth = $(this).attr('id').split('move')[1];
        alert(prevmonth);
        // $(".widget").remove();
        $("#event-cal").load("cal.html #cal"+prevmonth);
      });
    });
    $("#event-cal table td a").smoothScroll({offset: -80});
  }

  if ($('body').hasClass('news')) {
    $("#contents img").removeAttr("height");
  }
  if ($('body').hasClass('news-list')) {
    $("#content-nav").load("/news/index.html #content-nav .row");
  }
  if ($('body').hasClass('news-detail')) {
    $("#content-nav").load("/news/index.html #content-nav .row");
  }

  // スマホ以外電話リンクOFF
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
  if (!isMobile) {
      $('a[href^="tel:"]').on('click', function(e) {
          e.preventDefault();
      }).addClass('disable');
  }

   $('#menu .menu, a.btn, #navbar a').on('touchstart', function(){
     $(this).addClass('hover');
   }).on('touchend', function(){
     $(this).removeClass('hover');
   });

});

// movecal
function moveCal(){
}

//toTopの表示・非表示
function displayToTop(){
  var scrollTop = $(window).scrollTop();
  if (scrollTop < 300) {
    $(".to-page-top").fadeOut();
  } else {
    $(".to-page-top").fadeIn();
    $(".to-page-top a").smoothScroll({offset: -80});
  }
}
$(window).on("load scroll", displayToTop);

$(window).on("load resize", function(){
  if ($('body').hasClass('home')) {
    // if ($(window).width()<992) {
    //   var windowH = $(window).height();
    //   var featureH = $('#feature').height();
    //   var featurePadding = (windowH-featureH)/2;
    //   $('#feature').css({
    //     'paddingTop': featurePadding+'px',
    //     'paddingBottom': featurePadding+'px'
    //   });
    // }
    // if ($(window).width()>991) {
    //   var windowH = $(window).height();
    //   var featureH = 0;
    //       featureH = $('#feature').height();
    //   var featurePadding = (windowH-featureH)/2;
    //   $('#feature').css({
    //     'paddingTop': featurePadding+'px',
    //     'paddingBottom': featurePadding+'px'
    //   });
    // }
  }
  if ($('body').hasClass('products-top')) {
    $('#main .box').each(function(){
      var thumbW = $('#main .box').width()-10;
      $('#main .index-image span').height(thumbW);
    });
  }
});

$(function() {
    $(".effect div").css("opacity", "0"), $(window).scroll(function() {
        $(".effect").each(function() {
            var o = $(this).offset().top,
                n = $(window).scrollTop(),
                t = $(window).height();
            o - t + t / 10 < n ? $("div", this).css("opacity", "1") : $("div", this).css("opacity", "0")
        })
    })
});
